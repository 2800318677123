<template>

  <div class="vueTable2 position-relative table-responsive"> <!--table table-responsive-->
    <vuetable ref="vuetable"
      :api-url="apiUrl"
      :fields="fieldsDef"
      :css="css.table"
      data-path="data" 
      pagination-path="" 
      :detail-row-component="detailRowComponent"
      @vuetable:cell-clicked="onCellClicked"
      @vuetable:pagination-data="onPaginationData"
      @vuetable:loaded="onLoaded"
    > 
    <template slot="actions" scope="props">   
        <div class="custom-actions">
            <button class="btn btn-sm"  @click="onAction('view-item', props.rowData, props.rowIndex,props)">
                查看
            </button> 
        </div>
    </template>  
    </vuetable> 
    <span>  
      <div class="pt-2"><vuetable-pagination-info ref="paginationInfo" info-template="<small>顯示 {from} - {to}  共 {total} 筆</small>"></vuetable-pagination-info></div> 
      <vuetable-pagination ref="pagination" :css="css.pagination" class="pull-right" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
    </span> 
  </div>
</template>
<script>
 
import Vuetable from "vuetable-2/src/components/Vuetable";
//import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import VuetablePagination from "./vueTable2Pagination.vue"; //自訂定pagination長像
import vueCssStyle from "./vueTable2CssConfig.js"; 

export default {
  name: "vueTable2",
  components: {
    Vuetable,
    VuetablePagination, 
    VuetablePaginationInfo,  
  }, 
  props:{
   apiUrl: {
      type: String,
      required: true
    },
    fieldsDef: {
      type: Array,
      required: true
    },
    detailRowComponent: {
      type: String
    }
  }, 
  data() {
      return {
          css: vueCssStyle, 
      }
  }, 
  methods: {
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.$refs.paginationInfo.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page); 
      document.documentElement.scrollTop = 0;
      //console.log("---" + page);
    }, 
     onCellClicked (data) {
         this.$refs.vuetable.toggleDetailRow(data.data.id);
    }, 
    onLoaded() { 
      // console.log(this.$refs.vuetable.tableData);
      this.$emit("onLoaded", this.$refs.vuetable.tableData);
      // 展開第一筆資料
      this.$refs.vuetable.showDetailRow(this.$refs.vuetable.tableData[0].id);

    } 
  }
};
</script>

<style>
.pagination {
  margin-top: 1rem;
}

.vuetable-head-wrapper table.vuetable th.sortable {
  cursor: pointer
}
</style>
